import { Product, ProductListItem } from '@shared/models/product.model';

export const productsQuantity: (products: Product[]) => number = (products: Product[]) =>
  products.reduce((prev: number, curr: Product) => prev + (curr.selectedQuantity ?? 0), 0);

export const productsPrice: (products: Product[]) => number = (products: Product[]) =>
  products.reduce(
    (prev: number, curr: Product) => prev + (curr.selectedQuantity ?? 0) * (curr.wholesaleGrossPrice.price ?? 0),
    0
  );

export const productsListItemPrice: (products: ProductListItem[]) => number = (products: ProductListItem[]) =>
  products.reduce((prev: number, curr: ProductListItem) => prev + curr.wholesaleGrossPrice, 0);
