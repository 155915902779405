import { createFeatureSelector, createSelector } from '@ngrx/store';
import { productsPrice, productsQuantity } from '@shared/utils/map-functions';
import * as fromCart from './cart.reducer';
import { CartState } from './state.model';

export const selectCartState = createFeatureSelector<CartState>(fromCart.cartFeatureKey);

export const selectCartEntities = createSelector(selectCartState, fromCart.selectEntities);
export const selectCartAll = createSelector(selectCartState, fromCart.selectAll);
export const selectCartAllQuantity = createSelector(selectCartAll, productsQuantity);
export const selectCartAllPrice = createSelector(selectCartAll, productsPrice);
