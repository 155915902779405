import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Product } from '@shared/models/product.model';
import { CartState } from './state.model';
import { CartActions } from './cart.actions';

export const cartFeatureKey = 'cart';

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>({
  selectId: (product: Product) => product.id,
});

export const initialState: CartState = adapter.getInitialState({
  entityId: null,
});

export const reducer = createReducer(
  initialState,
  on(CartActions.upsertCart, (state, { product, quantity }) => {
    const selectedQuantity = quantity + (state.entities[product.id]?.selectedQuantity || 0);

    return adapter.upsertOne(
      {
        ...product,
        selectedQuantity,
      },
      state
    );
  }),
  on(CartActions.addOneCart, (state, action) =>
    adapter.updateOne(
      {
        id: action.entityId,
        changes: {
          selectedQuantity: state.entities[action.entityId].selectedQuantity + 1,
        },
      },
      state
    )
  ),
  on(CartActions.deleteOneCart, (state, action) => {
    if (state.entities[action.entityId]) {
      if (state.entities[action.entityId].selectedQuantity > 1) {
        return adapter.upsertOne(
          {
            ...state.entities[action.entityId],
            selectedQuantity: state.entities[action.entityId].selectedQuantity - 1,
          },
          state
        );
      }
    }
    return adapter.removeOne(action.entityId, state);
  }),
  on(CartActions.deleteCart, (state, action) => adapter.removeOne(action.entityId, state)),
  on(CartActions.clearCart, (state) => adapter.removeAll(state)),
  on(CartActions.setCart, (_, { cart }) => cart)
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
