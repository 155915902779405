import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Product } from '@shared/models/product.model';
import { CartState } from './state.model';

export const CartActions = createActionGroup({
  source: 'Cart',
  events: {
    'Upsert Cart': props<{ product: Product; quantity: number }>(),
    'Add One Cart': props<{ entityId: number }>(),
    'Delete One Cart': props<{ entityId: number }>(),
    'Delete Cart': props<{ entityId: number }>(),
    'Clear Cart': emptyProps(),
    'Set Cart': props<{ cart: CartState }>(),
  },
});
